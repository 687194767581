import React from "react";
import { FaInfoCircle, FaArrowRight } from "react-icons/fa";

const ComparisonResult = ({ monthlyContribution, yearlyContribution }) => {
  return (
    <div className="py-5 flex flex-col md:flex-row md:items-center px-1 gap-3">
      <FaInfoCircle className="text-[#8D1558] text-[25px]" />
      <div className="grid">
        <h3 className="text-[20px] text-[#8D1558] font-[400] font-[MiloMedium]">
          Vergleichsergebnis:
        </h3>
        <span className="flex gap-2 items-center justify-start">
          <p className="text-[18px] md:text-[20px] text-[#8D1558] font-[200]">
            Neuer Beitrag monatlich {monthlyContribution.toString().replace(".", ",")} €
          </p>
          <FaArrowRight className="text-[#8D1558] text-[14px] hidden md:inline" />{" "}
            </span>
      </div>
      {yearlyContribution > 0?<p className="text-[20px] md:text-[25px] text-green-600 font-[400] mt-4 md:mt-7">
        Ersparnis jährlich ca. {yearlyContribution.toString().replace(".", ",")} €
      </p>:<p className="text-[20px] md:text-[25px] text-red-600 font-[400] mt-4 md:mt-7">
        Mehrkosten jährlich ca. {yearlyContribution.toString().replace(".", ",")} €
      </p>}
    </div>
  );
};

export default ComparisonResult;

export const plzBereiche = {
    'Baden-Württemberg': ['68...', '69...', '70...', '71...', '72...', '73...', '74...', '75...', '76...', '77...', '78...', '79...', '88...', '89...'],
    'Bayern': ['80...', '81...', '82...', '83...', '84...', '85...', '86...', '87...', '88...', '89...', '90...', '91...', '92...', '93...', '94...', '95...', '96...', '97...'],
    'Berlin': ['10...', '12...', '13...', '14...'],
    'Brandenburg': ['03...', '14...', '15...', '16...', '17...', '19...'],
    'Bremen': ['28...'],
    'Hamburg': ['20', '21', '22'],
    'Hessen': ['34...', '35...', '36...', '60...', '61...', '63...', '64...', '65...'],
    'Mecklenburg-Vorpommern': ['17...', '18...', '19...'],
    'Niedersachsen': ['21...', '26...', '27...', '29...', '30...', '31...', '32...', '33...', '37...', '38...', '49...'],
    'Nordrhein-Westfalen': ['40...', '41...', '42...', '44...', '45...', '46', '47...', '48...', '49...', '50...', '51...', '52...', '53...', '57...', '58...', '59...'],
    'Rheinland-Pfalz': ['54...', '55...', '56...', '66...', '67...', '68...'],
    'Saarland': ['66...'],
    'Sachsen': ['01...', '02...', '04...', '08...', '09...'],
    'Sachsen-Anhalt': ['06...', '39...'],
    'Schleswig-Holstein': ['22...', '23...', '24...', '25...'],
    'Thüringen': ['07...', '98...', '99...']

};

export const getStateFromZipCode = (zipCode) => {
    const prefix = zipCode.substring(0, 2);
    for (const [state, prefixes] of Object.entries(plzBereiche)) {
        if (prefixes.some(p => prefix.startsWith(p.replace('...', '')))) {
            return state;
        }
    }
    return null;
};

export const calculateAnnualCosts = (employmentStatus, salary, additionalContribution) => {
    const baseRate = 0.146; // 14.6%
    const totalRate = baseRate + additionalContribution;
    const maxSalary = 62100;

    let annualCost;
    if (employmentStatus === 'employed') {
        annualCost = Math.min(salary, maxSalary) * (totalRate / 2);
    } else if (employmentStatus === 'self-employed') {
        annualCost = Math.min(salary, maxSalary) * totalRate;
    }

    return annualCost;
};

export const convertYesNoToBool = (data) => {
    let modi = {};
    Object.keys(data).forEach((i, k) => {
        //
        let d = data[i];
        Object.keys(d).forEach((k, j) => {
            let v = d[k];
            if (v.toString().toLowerCase() === 'yes' || v.toString().toLowerCase() === 'ja') v = true;
            if (v.toString().toLowerCase() === 'no' || v.toString().toLowerCase() === 'nein') v = false;
            d = {...d, [k]: v}
        });
        modi[i] = d
    });
   return modi
}


//function to make sure in the comparison ui questions for one topic are not appearing multiple times
export const mergeQuestionsByPrefix = (insuranceData) => {
    console.log(insuranceData);
    return insuranceData.map(insurance => {
      const mergedSections = insurance.sections.map(section => {
        const questionMap = {};
  
        section.questions.forEach(question => {
          // Extract the prefix (the part before the last underscore)
          const prefix = question.id.split('_')[0];
  
          if (!questionMap[prefix]) {
            // Initialize the group if it doesn't exist
            questionMap[prefix] = {
              ...question,
              value: question.value === 'yes' ? 'yes' : 'no', // Start with the current question's value
            };
          } else {
            // Merge the value if any of the grouped questions has a value of 'yes'
            if (question.value === 'yes') {
              questionMap[prefix].value = 'yes';
            }
          }
        });
  
        // Replace the original questions with the merged questions
        return {
          ...section,
          questions: Object.values(questionMap),
        };
      });
  
      // Return the updated insurance object with merged sections
      return {
        ...insurance,
        sections: mergedSections,
      };
    });
  }

//zip-code const
export const zipCodes = {
    'Baden-Württemberg': ['68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '88', '89'],
    'Bayern': ['80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97'],
    'Berlin': ['10', '12', '13', '14'],
    'Brandenburg': ['03', '14', '15', '16', '17', '19'],
    'Bremen': ['28'],
    'Hamburg': ['20', '21', '22'],
    'Hessen': ['34', '35', '36', '60', '61', '63', '64', '65'],
    'Mecklenburg-Vorpommern': ['17', '18', '19'],
    'Niedersachsen': ['21', '26', '27', '29', '30', '31', '32', '33', '37', '38', '49'],
    'Nordrhein-Westfalen': ['40', '41', '42', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '57', '58', '59'],
    'Rheinland-Pfalz': ['54', '55', '56', '66', '67', '68'],
    'Saarland': ['66'],
    'Sachsen': ['01', '02', '04', '08', '09'],
    'Sachsen-Anhalt': ['06', '39'],
    'Schleswig-Holstein': ['22', '23', '24', '25'],
    'Thüringen': ['07', '98', '99']
};

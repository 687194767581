import React, { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import ApplicationPageBasicUI from "./application/ApplicationPageBasicUI";
import FORM_DATA from "./application/ContactFormBuilder.json";
import InsuranceDetails from "../components/InsuranceDetails";
import { getAllInsurance, saveContactForms } from "../Apis";
import { convertYesNoToBool } from "../utils/utils";

const ApplicationPage = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState([]);
  const [isOpen, setIsOpen] = useState(0);
  const [groupForm, setGroupForm] = useState({});
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [insuranceRemData, setInsuranceRemData] = useState(null);
  const [userParsedData, setUserParsedData] = useState(null);
  const [isInsuranceDetailsOpen, setIsInsuranceDetailsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { insuranceId, employmentStatus } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const boolIsEmployed = employmentStatus === "1";
  const exemptClause = !boolIsEmployed ? "employer_info" : "additional_info";
  let counterArr = [];

  // Helper function to calculate the dynamic date
  const getDynamicDate= (isFuture) => {
    const today = new Date();
    const threeMonthsAgo = isFuture 
    ? new Date(today.setMonth(today.getMonth() + 3)) 
    : new Date(today.setDate(today.getDate() - 14));
    return threeMonthsAgo.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Preprocess formData with dynamic date
  const preprocessFormData = () => {
    const dynamicDateFuture = getDynamicDate(true);
    const dynamicDatePast = getDynamicDate(false);
    return FORM_DATA.map((section) => ({
      ...section,
      fields: section.fields.map((field) => {
        // Check if this is the specific field we want to modify
        if (field.fields?.key === "change_of_employement") {
          return {
            ...field,
            title: field.title?.replace("{{dynamic_date_future}}", dynamicDateFuture),
            fields: {
              ...field.fields,
              title: field.fields.title?.replace(
                "{{dynamic_date_future}}",
                dynamicDateFuture
              ),
            },
          };
        }else if(field.fields?.key === "min_employement_duration"){
          return {
            ...field,
            title: field.title?.replace("{{dynamic_date_past}}", dynamicDatePast),
            fields: {
              ...field.fields,
              title: field.fields.title?.replace(
                "{{dynamic_date_past}}",
                dynamicDatePast
              ),
            },
          };

        }
        // For other fields, return them unchanged
        return field;
      }),
    }));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get("data");
    if (data) {
      const convertedData = JSON.parse(atob(data));
      setUserParsedData(convertedData);
      setSelectedInsurance(convertedData.selectedInsurance);
    }

    // Preprocess the formData with dynamic date and filter out exempt sections
    console.log(preprocessFormData().filter((f) => f.key !== exemptClause));
    setFormData(preprocessFormData().filter((f) => f.key !== exemptClause));
    mainLoader();
  }, [location]);

  const mainLoader = async () => {
    try {
      const remData = await getAllInsurance();
      setInsuranceRemData(remData?.insuranceCompanies);
    } catch (e) {
      console.error("Error loading data:", e);
    }
  };

  const submitFormData = async (e) => {
    e.preventDefault();
    if (isOpen < formData.length) return;

    setIsSubmitting(true);
    const cleanData = convertYesNoToBool(groupForm);

    if (!selectedInsurance || !selectedInsurance._id) {
      alert("Error: Selected insurance information is missing");
      setIsSubmitting(false);
      return;
    }

    const networkData = {
      application: {
        ...cleanData,
        health_insurance: selectedInsurance._id,
      },
      processed: false,
      recipientCompanyId: selectedInsurance._id,
    };

    try {
      const response = await saveContactForms(networkData);
      if (response.success) {
        window.location.href =
          "https://www.dentnet.de/krankenkassen/erfolgsmeldung";
      } else {
        alert("Error submitting form: " + (response.error || "Unknown error"));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form: " + (error.message || "Unknown error"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSectionSave = (sectionData, index) => {
    setGroupForm((prevState) => ({
      ...prevState,
      ...sectionData.data,
    }));
    setIsOpen(counterArr.indexOf(Object.keys(sectionData?.data)[0]) + 1);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <h1 className="text-3xl md:text-4xl font-light text-center my-12 text-gray-700">
        Jetzt Mitgliedschaft bei der{" "}
        {selectedInsurance?.companyName || "Krankenkasse"} beantragen
      </h1>

      <div className="lg:hidden mb-8">
        {selectedInsurance && (
          <div className="bg-gray-100 p-4">
            <button
              onClick={() => setIsInsuranceDetailsOpen(!isInsuranceDetailsOpen)}
              className="w-full text-left font-semibold text-lg mb-2"
            >
              Ihre Auswahl {isInsuranceDetailsOpen ? "▲" : "▼"}
            </button>
            {isInsuranceDetailsOpen && (
              <InsuranceDetails insurance={selectedInsurance} />
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-2/3">
          <div className="space-y-4">
            {userParsedData &&
              insuranceRemData &&
              selectedInsurance &&
              formData.map((d, i) => {
                counterArr.push(d.key);
                return (
                  d.allowed && (
                    <ApplicationPageBasicUI
                      key={i}
                      isVisible={boolIsEmployed}
                      extraData={userParsedData}
                      userData={insuranceRemData}
                      isOpen={isOpen}
                      title={d.title}
                      data={d}
                      itemPos={i}
                      groupData={groupForm}
                      selectedInsurance={selectedInsurance}
                      onSave={(data) => handleSectionSave(data, i)}
                    />
                  )
                );
              })}
            <button
              onClick={submitFormData}
              type="button"
              disabled={isSubmitting || isOpen < formData.length}
              className={`bg-[#41a931] hover:bg-[#298b1a] text-white font-bold py-3 px-4 rounded w-full mt-6 transition duration-300 ${
                isSubmitting || isOpen < formData.length
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              {isSubmitting ? "Wird gesendet..." : "Antrag Senden"}
            </button>
          </div>
        </div>

        <div className="hidden lg:block w-full lg:w-1/3">
          {selectedInsurance && (
            <div className="bg-gray-100 p-6 sticky top-8">
              <InsuranceDetails insurance={selectedInsurance} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;

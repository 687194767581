import React from 'react';

const ServicePage = () => {
  return (
    <div className="insurance-services">
      <h1>Diese Leistungen bietet Ihnen die BKK Linde im DentNet</h1>
      <p>Mit der BKK Linde erhalten Sie im DentNet moderne Zahnmedizin zu günstigen Konditionen</p>

      <section className="insurance-comparison">
        <h2>Beiträge vergleichen und Kasse wechseln</h2>
        {/* Comparison form code here */}
      </section>

      <section className="services">
        <h2>Mit diesen Leistungen unterstützt die BKK Pfalz deine Mundgesundheit</h2>
        {/* Services description and details here */}
      </section>

      <section className="specific-services">
        <h3>Die BKK Linde bietet Ihnen wertvolle zahnmedizinische Leistungen im DentNet</h3>
        <ul>
          <li>Zahnreinigung</li>
          <li>Zahnersatz</li>
          <li>Implantate</li>
          <li>iKrone®</li>
        </ul>
      </section>

      <section className="seo-content">
        {/* SEO relevant content here */}
      </section>
    </div>
  );
}

export default ServicePage;

import { mergeQuestionsByPrefix } from "./utils";

const API_BASE_URL = process.env.REACT_APP_API_BASEURL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchAllInsurances = async () => {
  console.log('Fetching all insurances...');
  const response = await fetch(`${API_BASE_URL}/get/insurances`, {
    headers: {
      'Authorization': `Bearer ${API_KEY}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch insurances');
  const data = await response.json();

  console.log('Fetched insurances:', data);
  return data;
};
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FilterSection from "../components/FilterSection";
import ResultsSection from "../components/ResultsSection";
import { fetchAllInsurances } from "../utils/api";
import {
  filterInsuranceByRegion,
  insuranceCalculator,
  postalResolver,
} from "../utils/helpers";
import { mergeQuestionsByPrefix } from "../utils/utils";

const ComparisonPage = () => {
  const [userData, setUserData] = useState(null);
  const [currentInsurance, setCurrentInsurance] = useState(null);
  const [allInsurances, setAllInsurances] = useState([]);
  const [filteredInsurances, setFilteredInsurances] = useState([]);
  const [activeFilters, setActiveFilters] = useState(new Set());
  const [isCurrentInsuranceVisible, setIsCurrentInsuranceVisible] =
    useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    mainLoaderAsync();
  }, [location, navigate]);

  // Main loader
  async function mainLoaderAsync() {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get("data");
    const allInsurances = await fetchAllInsurances();
    if (data) {
      try {
        const decodedData = JSON.parse(atob(data));
        const tmpUserData = {
          ...decodedData,
          currentInsurance:
            allInsurances.insuranceCompanies[decodedData.insuranceIndex],
        };
        setUserData(tmpUserData);
        loadInsurances(tmpUserData);
      } catch (error) {
        console.error("Error parsing user data:", error);
        navigate("/preinformation", {
          state: {
            error: "Invalid data. Please enter your information again.",
          },
        });
      }
    } else {
      navigate("/preinformation", {
        state: { error: "No data provided. Please enter your information." },
      });
    }
  }

  const loadInsurances = async (userData) => {
    try {
      const allInsurances = await fetchAllInsurances();
      const userRegion = postalResolver(userData.zipCode);

      // Filter insurances by region
      let filteredInsurances = filterInsuranceByRegion(
        allInsurances.insuranceCompanies,
        userRegion
      );

      // Always include the user's current insurance
      const currentInsurance = {
        ...userData.currentInsurance,
        logo: userData.currentInsurance.logo || null,
      };

      // Ensure current insurance is in the list
      if (
        !filteredInsurances.some(
          (ins) => ins.companyName === currentInsurance.companyName
        )
      ) {
        filteredInsurances.push(currentInsurance);
      }

      filteredInsurances = mergeQuestionsByPrefix(filteredInsurances);

      const calculationData = {
        salary: userData.salary,
        employment: userData.employmentStatus,
        data: filteredInsurances,
      };

      const calculatedCosts = insuranceCalculator(calculationData);

      const insurancesWithCosts = filteredInsurances.map((insurance, index) => {
        const annualCost = parseFloat(calculatedCosts[index]);
        const monthlyCost = annualCost / 12;
        return {
          ...insurance,
          monthlyCost: monthlyCost.toFixed(2),
          annualCost: annualCost.toFixed(2),
          logo: insurance.logo || null,
        };
      });

      // Sort by monthly cost
      insurancesWithCosts.sort(
        (a, b) => parseFloat(a.monthlyCost) - parseFloat(b.monthlyCost)
      );

      const currentIns = insurancesWithCosts.find(
        (ins) => ins.companyName === currentInsurance.companyName
      );

      if (currentIns) {
        const currentInsuranceAnnualCosts = parseFloat(currentIns.annualCost);

        // Calculate savings for all insurances
        insurancesWithCosts.forEach((insurance) => {
          const savings =
            currentInsuranceAnnualCosts - parseFloat(insurance.annualCost);
          insurance.savings = savings.toFixed(2);
        });

        setCurrentInsurance(currentIns);

        // Filter other insurances for comparison
        const otherInsurances = insurancesWithCosts.filter(
          (ins) => ins.companyName !== currentInsurance.companyName
        );
        setAllInsurances(insurancesWithCosts);
        setFilteredInsurances(otherInsurances);
      } else {
        console.error("Current insurance not found in the list of insurances");
        setCurrentInsurance(currentInsurance);
        setAllInsurances(insurancesWithCosts);
        setFilteredInsurances(
          insurancesWithCosts.filter(
            (ins) => ins.companyName !== currentInsurance.companyName
          )
        );
      }
    } catch (error) {
      console.error("Error loading insurances:", error);
    }
  };

  const handleFilterChange = useCallback((category) => {
    setActiveFilters((prev) => {
      const newFilters = new Set(prev);
      if (newFilters.has(category)) {
        newFilters.delete(category);
      } else {
        newFilters.add(category);
      }
      return newFilters;
    });
  }, []);

  const applyFilters = useCallback(() => {
    if (activeFilters.size === 0) {
      setFilteredInsurances(
        allInsurances.filter((ins, index) => {
          ins.index = index;
          return ins.companyName !== currentInsurance?.companyName;
        })
      );
    } else {
      const filtered = allInsurances.filter((insurance) => {
        return Array.from(activeFilters).every((filterType) => {
          return insurance.sections.some((section) =>
            section.questions.some(
              (question) =>
                question.id.startsWith(filterType) && question.value === "yes"
            )
          );
        });
      });
      setFilteredInsurances(
        filtered.filter((ins, index) => {
          ins.index = index;
          return ins.companyName !== currentInsurance?.companyName;
        })
      );
    }
  }, [activeFilters, allInsurances, currentInsurance]);

  useEffect(() => {
    applyFilters();
  }, [activeFilters, allInsurances, currentInsurance, applyFilters]);

  return (
    <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 mb-8">
      <h1 className="text-4xl font-light text-center my-12 text-gray-700">
        Krankenkassenvergleich im DentNet
      </h1>

      <div className="flex flex-col lg:flex-row gap-8">
        <FilterSection
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          allInsurances={allInsurances}
          setIsCurrentInsuranceVisible={setIsCurrentInsuranceVisible}
        />
        <ResultsSection
          currentInsurance={currentInsurance}
          insurances={filteredInsurances}
          isCurrentInsuranceVisible={isCurrentInsuranceVisible}
        />
      </div>
    </div>
  );
};

export default ComparisonPage;

import {zipCodes} from "./constants"
//postal code-resolver
export const postalResolver = (zip = '') => {
    /**
     * Take two of the char (zip)
     */
    if (zip.length < 2) return null
    /**
     * Loop though cities and zipCodes
     */
    let returnName = null
    let triggerBreak = false
    //..........................................................
    for (let i = 0; i < Object.keys(zipCodes).length; i++) {
        const x = Object.keys(zipCodes)[i]
        //check for x
        const __codes = zipCodes[x];
        const __pickedZip = zip.substring(0, 2)
        //......................................................
        for (let k = 0; k < __codes.length; k++) {
            //compare and break
            if (__codes[k] === __pickedZip) {
                //return the city name
                returnName = x;
                //since found, trigger break from both loop
                triggerBreak = true
            }
        }
        //exit loop
        if (triggerBreak) break
    }
    //return city name
    return (returnName)
}

//sort insurance by region and operating within the region
export const filterInsuranceByRegion = (dataArray = [], userRegion) => {
    /**
     * Return equal size of array but filtered
     */
    if (!dataArray || !userRegion) return
    //confirmed operating arrays
    let __confirmedArrays = []
    //loop through all insurance
    for (let i = 0; i < dataArray.length; i++) {
        const __tmpInsurance = dataArray[i];
        //lookup for each element
        const __isCheck = __tmpInsurance?.operatingRegions.some(x => x.toLowerCase().includes(userRegion.toLowerCase()))
        if (__isCheck) {
            //push found insurance within the region in the array
            __confirmedArrays.push(__tmpInsurance)
        }
    }
    //console.log(__confirmedArrays)
    //return dey matched data
    return __confirmedArrays;
}

//comparison functions
export const insuranceCalculator = (data, isMultiple = true) => {
    /**
     * Extract data from [data] args
     * @type {number}
     */
        //User Maximum Salary
    const USER_MAXIMUM_SALARY = 62100
    const IS_SELF_EMPLOYED = data?.employment === 2
    const USER_SALARY = Number(data?.salary) > USER_MAXIMUM_SALARY ? USER_MAXIMUM_SALARY : Number(data?.salary)
    /**
     * IF USER IS SELF_EMPLOYED, LET DIVISIBLE BY 2
     */
    const DIVISIBLE_VALUE = 2
    //This is set to TURE because only filtered insurances are passed
    const IS_INSURANCE_OPERATING = true
    if (!IS_INSURANCE_OPERATING) return null //No calculation was done
    /**
     * Begin calculations
     */
    const RESULT = []
    const DATA = data?.data
    //Only for loop for once
    for (let i = 0; i < DATA.length; i++) {
        //get insurance contribution percentage
        const INSURANCE_PERCENTAGE = (14.6 / 100) //percentage of what value ?
        const INSURANCE_AMOUNT = Number(DATA[i]?.additionalContribution) / 100
        //IF IS EMPLOYED, DIVISIBLE BY 2
        let calc = 0
        if (!IS_SELF_EMPLOYED) calc = USER_SALARY * ((INSURANCE_PERCENTAGE + INSURANCE_AMOUNT) / DIVISIBLE_VALUE)
        else calc = USER_SALARY * (INSURANCE_PERCENTAGE + INSURANCE_AMOUNT)
        RESULT.push(Number(calc).toFixed(2))
    }
    //sort result array and pick the least
    if (!isMultiple) RESULT.sort()
    return (isMultiple) ? RESULT : [RESULT[0]]
}